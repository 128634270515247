import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style-pages.css';
import { BsPerson } from "react-icons/bs";
import logoBrend from '../image/kotak.png'

const ProjectSyra = () => {

    return (
        <div>
            <div className="container pt-3 ">
                <img className='logoBrand' src={logoBrend} alt='Logo Brannd' />
                <div className='row justify-content-center'>
                    <h1 className='text-center mt-5'>SYRA ECOSYSTEM</h1>
                    <div className='row justify-content-center mt-5'>
                        <div onClick={() => window.open('https://docs.google.com/document/d/1rYC1HAGuYK-jrLlX9Wu6VtMel8DRMMi8/edit?usp=sharing&ouid=101843543939909513193&rtpof=true&sd=true ', '_blank')} className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-3 mb-5 border border-success conten '>
                            <div>
                                <BsPerson className='color-success' />
                                <p className='fw-bold mt-3 text-center'> SYRAFINANCE </p>
                                <p className='mt-3 text-center'> SYRAFINANCE is a Fintech Platform. See Whitepaper! </p>
                            </div>
                        </div>
                        <div onClick={() => window.open('http://academy.syra.co.id', '_blank')} className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-3 mb-5 border border-success conten '>
                            <div>
                                <BsPerson className='color-success' />
                                <p className='fw-bold mt-3 text-center'>SYRACADEMY</p>
                                <p className='text-center'>SYRACADEMY is under development. We will be back again soon</p>
                            </div>
                        </div>
                        <div onClick={() => window.open('http://gattot.syra.co.id', '_blank')} className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-3 mb-5 border border-success conten '>
                            <div>
                                <BsPerson className='color-success' />
                                <p className='fw-bold mt-3 text-center'>GATTOT</p>
                                <p className='text-center'>GATTOT is under development. We will be back again soon</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectSyra
